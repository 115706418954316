import AccountService from '@/services/account.service.js'
import EventBus from '@/util/EventBus'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'ChangeSmppPassword',
  components: { HeaderTopDashboard },
  data: function () {
    return {
      key: '',
      copyPasswordAlert: false,
    }
  },
  mounted () {
    this.getNewSmppPassword()
  },
  methods: {
    copyPassword () {
      navigator.clipboard.writeText(this.key)
      this.copyPasswordAlert = false
      EventBus.$emit('showAlert', 'success', this.$t('El nuevo password de SMPP ha sido copiado al portapapeles'))
    },
    updateSmppPassword () {
      AccountService.updateSmppPassword({ key: this.key }).then(() => {
        this.copyPasswordAlert = true
      })
    },
    getNewSmppPassword () {
      AccountService.getNewSmppPassword().then((data) => {
        this.key = data.key
      })
    },
  },
}
